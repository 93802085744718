<template>
  <div
    class="app-container apply__myApply"
    style="background-color: #f6f6f6; padding: 180px 0px 150px; min-height: 800px;"
  >
    <!-- 내 지원서 로그인 form -->
    <div
      v-if="checkFinished && !loggedIn"
      style="width: 500px; margin: 0 auto;"
    >
      <div class="apply__myApply--container">
        <div class="align-top">
          <h1 class="apply__tit font-bold text-2xl mt-5 mb-9 mt-10">
            <span class="highlight">
              <span>📃 내 지원서</span>
            </span>
            <p class="text-sm font-normal mt-3">
              지원서를 제출한 이메일과 비밀번호를 입력해 주세요.
            </p>
          </h1>
          <el-form
            class="custom-form"
            :model="loginForm"
            inline
            style="width: 300px; margin: 0 auto;"
          >
            <div class="pt-6">
              <el-form-item prop="name">
                <el-input
                  v-model="loginForm.name"
                  :placeholder="$t('recruit.form.name')"
                  name="namefield"
                  class="filter-item"
                  style="width: 300px"
                />
              </el-form-item>
            </div>
            <div>
              <el-form-item prop="email">
                <el-input
                  v-model="loginForm.email"
                  :placeholder="$t('recruit.form.email')"
                  type="email"
                  class="filter-item"
                  style="width: 300px"
                />
              </el-form-item>
            </div>
            <div>
              <el-form-item prop="password">
                <el-input
                  v-model="loginForm.password"
                  show-password
                  :placeholder="$t('recruit.form.password')"
                  type="password"
                  class="filter-item"
                  style="width: 300px"
                  @keyup.native.enter="login"
                />
              </el-form-item>
            </div>
          </el-form>
          <div class="applyNew__btnWrap">
            <button
              style="width: 220px; height: 60px;"
              class="mt-4 customBtn lg white"
              @click="login"
            >
              <span style="font-size: 18px;">조회하기</span>
            </button>
          </div>
        </div>
      </div>
      <div class="mt-2 mr-2 text-right">
        <span class="mr-3 text-sm text-gray-600">비밀번호를 잊으셨나요?</span>
        <button
          class="text-sm font-bold"
          style="border-bottom: 1px solid #666"
          @click="resetPassword()"
        >
          비밀번호 초기화
        </button>
      </div>
    </div>
    <!-- // 내 지원서 로그인 form -->

    <!-- 지원서 리스트 -->
    <div
      v-else
      style="width: 800px; margin: 0 auto;"
    >
      <div class="text-right mb-4">
        <el-button
          type="plain"
          class="inline-block"
          style="height: 55px;"
          @click.native="$router.push('/recruit/apply')"
        >
          📝 새로운 지원서 등록하기
        </el-button>
      </div>
      <div
        class="apply__myApply--container"
        style="padding: 0.8rem; width: 800px;"
      >
        <div
          v-if="myApplications.length > 0"
          class="align-top applyList"
        >
          <div
            v-for="(item, idx) in myApplications"
            :key="idx"
            class="block border-b-2 applyList__item"
            style="border-bottom-width: 1px;"
          >
            <div
              style="padding: 1rem 1.2rem;"
              @click="handleApplications(item)"
            >
              <span class="inline-block font-bold text-lg mb-2 mr-4">
                <span class="highlight required">
                  <span>{{ item.name }}</span>
                </span>
              </span>
              <span class="inline-block font-normal text-xl text-gray-400 mr-4">|</span>
              <span class="inline-block font-bold text-base mr-4 text-gray-800">{{ appliableList.length ? appliableList.find(j => j.id === item.jobId ).name : '' }}</span>
              <span class="inline-block font-normal text-xl text-gray-400 mr-4">|</span>
              <span class="inline-block font-normal text-sm mr-4 text-gray-600">{{ item.appliedDate }}</span>
              <span class="inline-block font-bold float-right">
                <button
                  v-if="item.isTemp"
                  class="edit__btn text-xs"
                >수정</button>
                <button
                  v-else
                  :disabled="item.isTemp ? disabled : ''"
                  class="edit__btn text-xs"
                >완료</button>
              </span>
            </div>
          </div>
        </div>
        <div
          v-else
          class="parentCenter text-center"
        >
          <div
            class="text-md childCenter"
            style="height: 50px;"
          >
            현재의 계정으로 지원하신 지원서가 없습니다.<br>
            <b>"새로운 지원서 등록하기"</b>를 통해서 지원서를 등록해주세요.
          </div>
        </div>
      </div>
      <div class="mt-2">
        <span class="mr-3 text-sm text-gray-700">다른 계정으로 로그인하고 싶으신가요?</span>
        <button
          class="text-sm font-bold"
          style="border-bottom: 1px solid #666"
          @click="logOut()"
        >
          로그아웃
        </button>
      </div>
    </div>
    <!-- // 지원서 리스트 -->
  </div>
</template>

<script>
import { login, getAccountInfo } from '@/api/applicant';
import { getAllJobs } from '@/api/job';
import '@/plugins/element.js';

export default {
  data() {
    return {
      checkFinished: false,
      loggedIn: false,
      loginForm: {
        name: '',
        email: '',
        password: '',
      },
      myApplications: [],
      appliableList: [],
    };
  },
  async created() {
    window.localStorage.clear();
    await this.checkLogin();
    this.checkFinished = true;
    await this.getJobList();
  },
  methods: {
    async handleApplications(application) {
      if(application.isTemp){
        if(application.isMobileSimpleApply){
          await this.$store.dispatch('user/setJobId', application.jobId);
          await this.$store.dispatch('app/setEditSimpleApply', application.isMobileSimpleApply);
        }
        this.editApply(application.id);
      }else{
        this.reviewApply(application.id);
      }
    },
    async getJobList() {
      if (window.navigator.userAgent.indexOf('Trident') > -1) {
        this.applicantForm.name = document.getElementsByName('namefield')[0].value;
      }
      const query = {};
      const result = await getAllJobs(query);
      // console.log(result)
      // const list = [];
      // result.data.items.forEach(type => {
      //   type.job.forEach(j => {
      //     list.push({
      //       type: type.name,
      //       type_en: type.name_en,
      //       ...j
      //     });
      //   });
      // });
      this.appliableList = result.data.items;
    },
    async checkLogin() {
      if (!this.$store.getters.userToken) {
        return false;
      }

      const getApplicationsResult = await getAccountInfo();
      const userData = getApplicationsResult.data.accountInfo;

      await this.$store.dispatch('user/setUid', userData.uid);
      await this.$store.dispatch('user/setUserForm', {
        name: userData.name,
        birthday: userData.birthday,
        email: userData.email,
        phone: userData.phone,
        recruitPoolCheck: userData.recruitPoolCheck
      });

      this.myApplications = getApplicationsResult.data.accountInfo.applicants;
      this.loggedIn = true;
      this.myApplications = this.myApplications.reverse();
    },
    async login() {
      const loginResult = await login(this.loginForm);

      if (window.navigator.userAgent.indexOf('Trident') > -1) {
        this.applicantForm.name = document.getElementsByName('namefield')[0].value;
      }
      if(!loginResult.data.success){
        this.$message({
          message: loginResult.data.message,
          type: 'error'
        });
        return false;
      }
      await this.$store.dispatch('user/setToken', loginResult.data.token);
      await this.$store.dispatch('user/setUid', loginResult.data.user.uid);
      await this.$store.dispatch('user/setUserForm',loginResult.data.user);

      const getApplicationsResult = await getAccountInfo();

      this.myApplications = getApplicationsResult.data.accountInfo.applicants;
      if(getApplicationsResult.data.accountInfo.isTempPwd){
        this.$router.push('/recruit/change-pwd');
      }else if(!this.myApplications.length > 0){
        this.$store.dispatch('app/setIsSimpleApply',false);
        this.$router.push('/recruit/apply');
      }else{
        this.loggedIn = true;
        this.myApplications = this.myApplications.reverse();
      }
    },
    async logOut() {
      if (window.navigator.userAgent.indexOf('Trident') > -1) {
        this.applicantForm.name = document.getElementsByName('namefield')[0].value;
      }
      document.cookie = 'username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      await this.$store.dispatch('user/setToken', '');
      await this.$store.dispatch('user/setUid', '');
      await this.$store.dispatch('user/setUserForm', {});
      this.$router.push('/recruit/sign-up');
    },
    resetPassword() {
      this.$router.push('/recruit/reset-pwd');
    },
    async editApply(applicantId) {
      await this.$store.dispatch('user/setApplicationId', applicantId);
      this.$router.push('/recruit/edit-apply');
    },
    async reviewApply(applicantId) {
      await this.$store.dispatch('user/setApplicationId', applicantId);
      this.$router.push('/recruit/review-apply');
    }
  }
};
</script>

<style lang="scss" scooped>
@import '@/styles/apply.scss';
.childCenter{
  display: inline-block;
  vertical-align: middle;
}
.parentCenter{
  height: 510px
}
.parentCenter::after{
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 0; height: 100%;
}
</style>
